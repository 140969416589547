import React from "react";
import { Grid, Avatar, Typography, Box, Divider } from "@mui/material";
import cn from "classnames";

import { makeStyles } from "../AppContainer/mui-theme";
import { getCategoryImage } from "../../utils/categories";
import { get, upperCase } from "lodash";
import { NavigateNext, RestaurantMenu } from "@mui/icons-material";

const CategoryItem = (props) => {
  const { category, T, appStyles, onClick } = props;

  const { classes } = useStyles();

  const direction = get(
    appStyles,
    "categoriesPageConfig.categoriesPageLayout",
    "horizontal"
  );

  const horizontalLayout = (
    <Grid container item xs={12} onClick={onClick}>
      <Box p={1} className={cn(classes.horizontalContainer, classes.root)}>
        <Avatar
          variant="rounded"
          src={getCategoryImage(category)}
          style={{
            ...appStyles.CategoryMenuItems,
            height: 100,
            width: 100,
          }}
        >
          <RestaurantMenu />
        </Avatar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "60%",
            margin: "0 20px",
          }}
        >
          <Typography
            sx={{
              color: appStyles.textColor,
              fontSize: "18px",
              fontWeight: "900",
              ...appStyles.CategoryMenuLabel,
            }}
          >
            {upperCase(T(category.name))}
          </Typography>
          <Typography variant="body2" sx={{ color: "#838383" }}>
            {T(category.description)}
          </Typography>
        </Box>
        <NavigateNext />
      </Box>
      <Divider sx={{ width: "100%" }} />
    </Grid>
  );

  const verticalLayout = (
    <Grid container item xs={6} onClick={onClick}>
      <Box p={1} className={cn(classes.verticalContainer, classes.root)}>
        <Avatar
          variant="rounded"
          src={getCategoryImage(category)}
          className={cn(classes.verticalAvatar)}
          style={{
            ...appStyles.CategoryMenuItems,
            height: 100,
            width: 100,
          }}
        >
          <RestaurantMenu />
        </Avatar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "15px",
          }}
        >
          <Typography
            sx={{
              color: appStyles.textColor,
              fontSize: "18px",
              fontWeight: "900",
              ...appStyles.CategoryMenuLabel,
            }}
          >
            {upperCase(T(category.name))}
          </Typography>
          <Typography variant="body2" sx={{ color: "#838383" }}>
            {T(category.description)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );

  return direction === "horizontal" ? horizontalLayout : verticalLayout;
};

export default CategoryItem;

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  verticalContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  horizontalContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  verticalAvatar: {
    margin: "0 auto",
  },
  horizontalAvatar: {},
}));
