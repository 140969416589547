import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import CategoryItem from "./category-item";
import { navigateTo, setParams, getParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import { isNumber } from "lodash";
import BagItemsIndicator from "../BagItemsIndicator";
import { openAuthView, openCheckoutView } from "../../store/app/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getPriceDetailsForCheckout,
  predictedOrderPrice,
} from "../../store/selectors";
import { saveOrderIfNeeded } from "../../store/order/actions";

const CategoriesView = (props) => {
  const dispatch = useDispatch();
  const { menuData, location, order, T, app, appStyles } = props;
  const priceDetails = useSelector((state) =>
    getPriceDetailsForCheckout(state, props)
  );
  const predictedOrderPriceValue = useSelector((state) =>
    predictedOrderPrice(state, props)
  );

  const handleCategoryClick = (category) => {
    navigateTo(
      setParams("/order", {
        ...getParams(location),
        toCategoryName: category.name,
        toCategoryId: category.id,
      })
    );
  };

  const handleCheckoutClicked = () => {
    const {
      location,
      user,
      pageContext: { businessAppConfiguration },
    } = props;

    const { requireLoginForOrder } = businessAppConfiguration;
    if (requireLoginForOrder && !user.loggedIn) {
      dispatch(openAuthView());
    } else {
      dispatch(openCheckoutView());
      navigateTo(
        setParams(`/order`, {
          ...getParams(location),
          checkoutOpen: true,
        })
      );
    }
  };

  useEffect(() => {
    if (order.shouldSyncOrderItems) {
      dispatch(
        saveOrderIfNeeded(props.pageContext.servingOptions, {
          force: order.shouldSyncOrderItems,
        })
      );
    }
  }, [order.shouldSyncOrderItems]);

  return (
    <AppContainer.Content>
      <Grid container spacing={2} sx={{ p: "16px" }}>
        {menuData.map((category) => (
          <CategoryItem
            category={category}
            onClick={() => handleCategoryClick(category)}
            {...props}
          />
        ))}
      </Grid>
      {order.orderItems.length > 0 && (
        <AppContainer.Footer
          relativePosition={app.keyboardOpen}
          hideOnDesktop
          appStyles={appStyles}
          transparentGradient
          center
        >
          <AppContainer.Footer.Button
            appStyles={appStyles}
            spread
            onClick={handleCheckoutClicked}
          >
            <span>{T("View Order")}</span>
            <span style={{ display: "flex", alignItems: "center" }}>
              {priceDetails && predictedOrderPriceValue > 0 && (
                <span
                  style={
                    appStyles.rtl ? { marginLeft: 10 } : { marginRight: 8 }
                  }
                >
                  {isNumber(predictedOrderPriceValue)
                    ? `${
                        props.pageContext.business.currencySymbol
                      }${predictedOrderPriceValue.toFixed(
                        appStyles.pricesFixedPoint
                      )}`
                    : ""}
                </span>
              )}
              <BagItemsIndicator
                counter={order.orderItems.length}
                appStyles={appStyles}
              />
            </span>
          </AppContainer.Footer.Button>
        </AppContainer.Footer>
      )}
    </AppContainer.Content>
  );
};

export default CategoriesView;
